import React from "react";
import {
  PartnerContainer,
  PartnerURL,
  PartnerBlock,
  PartnerLogo,
} from './team-components.styled';



const Partner = ({ partners }) => (
  <PartnerContainer>
    {partners.map((value, index) => {
      if (value.url) {
        return <PartnerURL
          href={value.url}
          key={index}
          target="_blank"
        >
          <PartnerLogo
            image={value.logo?.childImageSharp.gatsbyImageData}
            alt={value.name}
          />
        </PartnerURL>
      }
      return (
        <PartnerBlock key={index}>
          <PartnerLogo
            fixed={value.logo?.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={value.name}
          />
        </PartnerBlock>
      )
    })
    }
  </PartnerContainer>
)

export default Partner;
