import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import Team from "../components/team-components/team";
import Partner from "../components/team-components/partner";
import NorwayGrantsSrc from "../assets/img/Norway-grants.png"

const TeamPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const partners = data.partners.frontmatter || {};
  const team = data.team.edges.map(element => element.node.childMarkdownRemark.frontmatter) || [];
  const partnerList = data.partner_list.edges.map(element => element.node.childMarkdownRemark.frontmatter) || [];

  return (
    <>
      <SEO
        canonical="/team/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <br />
          <Team members={team} />

          <br />

          <PageTitle>{partners.title}</PageTitle>
          <br />
          <Partner partners={partnerList} />

          <PageHTML>
            <p style={{ textAlign: "center" }}><small>BeTRITON LLC. have signed contract Nr.SKV-L-2021/262 (30.08.2021.) with the Investment and Development Agency of Latvia to receive support under the program "Promotion of International Competitiveness" co-financed by the European Regional Development Fund.</small></p>
          </PageHTML>

          <br />

          <div style={{ textAlign: "center" }} >
            <img src={NorwayGrantsSrc} alt="Norway Grants" style={{ maxWidth: "200px", margin: "auto" }} />
          </div>

          <PageHTML>
            <p style={{ textAlign: "center", fontStyle: "italic" }}><small>Working together for a green, competitive and inclusive Europe.</small></p>
            <p style={{ textAlign: "center" }}><small>BeTRITON SIA receives a grant of EUR 75,000 (total project funding of EUR 106,500) from the Norwegian grant "Development of Green Innovation and Information and Communication Technology Products", contract no. NP-2022/2, 26.01.2022, project goal - Development of a new sustainable tourism and mobility solution "BeTRITON DIY".</small></p>
          </PageHTML>

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default TeamPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/team.md/"}) {
    frontmatter {
      title
    }
    html
  }
  partners: markdownRemark(fileAbsolutePath: {regex: "/content/pages/partners.md/"}) {
    frontmatter {
      title
    }
    html
  }
  team: allFile(filter: {absolutePath: {regex: "/content/team/"}, childMarkdownRemark: {frontmatter: {is_visible: {eq: true}}}}, sort: {fields: childrenMarkdownRemark___frontmatter___order, order: DESC}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            name
            title
            description
            email
            image{
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  height: 200
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
            linkedin
            instagram
            facebook
            tumblr
            website
          }
        }
      }
    }
  }
  partner_list: allFile(filter: {absolutePath: {regex: "/content/partners/"}}, sort: {fields: childrenMarkdownRemark___frontmatter___order, order: DESC}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            name
            url
            logo{
              childImageSharp {
                gatsbyImageData(
                  height: 80
                )
              }
            }
          }
        }
      }
    }
  }
}  
`;

