import React from "react";
import {
  TeamContainer,
  Card,
  SocialWrapped,
  SocialIcons,
  Social,
  Image,
  Name,
  Title,
  Description,
  Email,
} from './team-components.styled';
import {
  Icon,
} from '../../ui-components';


const iconSize = 14;

const TeamCard = ({ member }) => {
  return (
    <Card>
      <SocialWrapped>
        <Image
          image={member.image?.childImageSharp.gatsbyImageData}
          alt={member.name}
        />
        <SocialIcons>
          {member.linkedin
            ? (
              <Social href={member.linkedin} target="_blank">
                <Icon
                  name="linkedin"
                  width={iconSize}
                  height={iconSize}
                  noFill
                />
              </Social>
            )
            : ''
          }

          {member.instagram
            ? (
              <Social href={member.instagram} target="_blank">
                <Icon
                  name="instagram"
                  width={iconSize}
                  height={iconSize}
                  noFill
                />
              </Social>
            )
            : ''
          }
          {member.facebook
            ? (
              <Social href={member.facebook} target="_blank">
                <Icon
                  name="facebook"
                  width={iconSize}
                  height={iconSize}
                  noFill
                />
              </Social>
            )
            : ''
          }
          {member.twitter
            ? (
              <Social href={member.twitter} target="_blank">
                <Icon
                  name="twitter"
                  width={iconSize}
                  height={iconSize}
                  noFill
                />
              </Social>
            )
            : ''
          }
          {member.tumblr
            ? (
              <Social href={member.tumblr} target="_blank">
                <Icon
                  name="bicycle"
                  width={iconSize}
                  height={iconSize}
                  noFill
                />
              </Social>
            )
            : ''
          }
          {member.website
            ? (
              <Social href={member.website} target="_blank">
                <Icon
                  name="briefcase"
                  width={iconSize}
                  height={iconSize}
                  noFill
                />
              </Social>
            )
            : ''
          }
        </SocialIcons>
      </SocialWrapped>
      <Description>
        <Name>{member.name}</Name>
        <Title>{member.title}</Title>
        <Email href={`mailto:${member.email}`}>{member.email}</Email>
      </Description>
    </Card>
  )
}


const Team = ({ members }) => (

  <TeamContainer>
    {members.map((member, index) => (
      <TeamCard
        key={index}
        member={member}
      />
    ))}
  </TeamContainer>

)


export default Team;
