import styled from "styled-components";
import {
  GatsbyImage,
} from "gatsby-plugin-image";


export const TeamContainer = styled.div`
  max-width: 880px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 1px 11px;

`;

export const cutSize = 5;

export const Card = styled.div`
  position: relative;
  display: block;
  background: ${({ theme }) => theme.primary.dark};
  padding-top: 3px;

  @media ${({ theme }) => theme.device.phone}{
    margin: 0 auto 2px;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-left: ${cutSize}px solid ${({ theme }) => theme.primary.dark};
    border-bottom: ${cutSize}px solid transparent;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-right: ${cutSize}px solid ${({ theme }) => theme.primary.dark};
    border-bottom: ${cutSize}px solid transparent;
  }

`;

export const SocialWrapped = styled.div`
  position: relative;
`;

export const SocialIcons = styled.div`
  position: absolute;
  bottom: -12px;
  right: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1;
`;

export const Social = styled.a`
  background: ${({ theme }) => theme.primary.main};
  display: inline-block;
  padding: 4px;
  line-height: 10px;
  margin-left: 2px;

  &:hover{
    background: ${({ theme }) => theme.primary.dark};
  }

  svg{
    fill: ${({ theme }) => theme.text.white};
  }
`;


export const cutSize2 = 4;


export const Image = styled(GatsbyImage)`
  position: relative;
  margin-bottom: -6px;
  margin-left: -2px;
  margin-right: -2px;
  z-index: 1;
  display: block;
  line-height: 0;


  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-top: ${cutSize2}px solid ${({ theme }) => theme.primary.dark};
    border-left: ${cutSize2}px solid transparent;
    border-bottom: ${cutSize2}px solid ${({ theme }) => theme.primary.dark};
    z-index: 100;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    border-top: ${cutSize2}px solid ${({ theme }) => theme.primary.dark};
    border-right: ${cutSize2}px solid transparent;
    border-bottom: ${cutSize2}px solid ${({ theme }) => theme.primary.dark};
    z-index: 1;
  }
`;

export const Name = styled.h3`
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 12px;
  text-align: center;
  margin: 12px 0 4px;
  padding: 0;
  color:  ${({ theme }) => theme.common.white};
`;

export const Title = styled.h2`
  font-size: 12px;
  line-height: 14px;
  font-family: "Quantico", sans-serif;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 0 0;
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.secondary.dark};
  
`;

export const Description = styled.div`
  margin-bottom: 5px;
  padding: 11px 8px;
`;

export const Email = styled.a`
  display: block;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.primary.main};
`;

export const PartnerContainer = styled.div`
  max-width: ${({ theme }) => theme.size.tablet}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 6px 12px;
  margin: 0 auto 32px;

  @media ${({ theme }) => theme.device.phone}{
    /* display: block; */
  }
`;

export const PartnerURL = styled.a`
  display: block;
`;

export const PartnerBlock = styled.div`
  display: block;
`;

export const PartnerLogo = styled(GatsbyImage)`
  margin: 0 0 8px 0;
  /* height: 140px;
  width: 100%; */

  @media ${({ theme }) => theme.device.phone}{
    margin: 0 16px 8px;
  }  
`;